import { AlertHandler, ErrorAlert } from '../components/react/alert_handler/alert_handler';

/**
 * Checks if response status is 2.x.x, else return error
 *
 * @param {XMLHTTPResponse} response - http response from fetch
 * @returns {Promise} resolved or rejected response
 */
const checkStatus = async (response) => {
  if (response.ok) {
    return Promise.resolve(response);
  }

  // Request failed, attempt to parse server defined error message
  try {
    let error = await response.json();
    // Parse successful
    return Promise.reject(error.message ? new Error(error.message) : error);
  } catch (error) {
    // Parse not successful
    return Promise.reject(new Error(`${response.status}: ${response.statusText}`));
  }
};

/**
 * Checks if response status is 2.x.x, else return error
 *
 * @param {object} error - error from API that might contain one or many errors
 * @param {AlertHandler} [alertHandler = new Alert Handler] response - to show the errors
 * @returns {AlertHandler}
 */
const showErrors = (error, alertHandler = new AlertHandler()) => {
  if (error.ok) return;
  if (error.message) return alertHandler.render(ErrorAlert, error.message);
  if (error.errors) return error.errors.forEach((e) => alertHandler.render(ErrorAlert, e.message));
  return alertHandler.render(ErrorAlert, 'Something did not work');
};

export { checkStatus, showErrors };
